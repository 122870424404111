<template>
    <div class="content">
        <div class="icon not-paid">
            <img src="/build/images/warning.svg">
        </div>

        <div class="title">
            {{ !$auth.user().instance || $auth.user().instance.status == 'new' ? $t('not-paid-first.title') : $t('not-paid.title') }}
        </div>

        <div class="description">
            {{ !$auth.user().instance || $auth.user().instance.status == 'new' ? $t('not-paid-first.description') : $t('not-paid.description') }}
        </div>

        <div class="button-action">
            <button class="btn-tbf blue center" @click="openModalSubscription">
                <div class="text">{{ !$auth.user().instance || $auth.user().instance.status == 'new' ? $t('not-paid-first.button') : $t('not-paid.button')}}</div>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
            }
        },
        methods: {
            openModalSubscription(){
                if(!this.$auth.user().instance){
                    this.$root.$emit('open_modal', 'required_instance');
                }else{
                    this.$root.$emit('open_modal', 'change_subscription');
                }
            }
        }
    }
</script>